<template>
  <v-col cols="12" md="5" sm="12">
    <v-card class="s-pa-n3 pa-md-5" height="100%" id="resumo-aluno">
      <v-card-title class="titulo-card ma-0 pa-0">
        <v-icon large left color="primary">mdi-star</v-icon>
        <h5>Olá, {{ primeiroNome(aluno.nom_aluno) }}</h5>
      </v-card-title>

      <v-card-actions class="s-mt-n5  pa-0">
        <v-row class="info-candidato-2">
          <v-col cols="12" class="order-md-first">
            <p>Sua matrícula será no:</p>
            <p><b>Curso:</b> {{ aluno.curso }}</p>
            <p><b>Turma Composição:</b> {{ aluno.turma }}</p>
            <p class="s-mr-n3">
              <b>Unidade:</b> {{ aluno.campus }} | <b>Turno:</b>
              {{ aluno.turno }}
              <!-- <template v-if="aluno.semestre != null">
                | <b>Semestre:</b> {{ aluno.semestre }}º semestre
              </template>
            </p>
            <template v-if="aluno.previsao_formatura && aluno.semestre">
              <p>
                <tooltip-question
                  texto="Essa é uma estimativa da sua previsão de conclusão, mas que poderá sofrer variações no decorrer de seu curso."
                  tamanho="195px"
                />
                <b>Previsão de conclusão:</b> {{ aluno.previsao_formatura }}
              </p>
            </template> -->
            </p></v-col
          >
        </v-row>
        <button
          class="s-mt-n5 btn-a btn-a-pri d-print-none"
          :class="{
            'btn-large': $vuetify.breakpoint.width < 600,
            'btn-biggest': fontSize >= 1.75
          }"
        >
          <v-icon class="white--text s-mr-n1 text-md-body-2"
            >mdi-arrow-right</v-icon
          >
          <a :href="link" class="white--text"
            >Prosseguir com minha matrícula
          </a>
        </button>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import primeiroNome from "@/core/util/functions";
//import TooltipQuestion from "../shared/TooltipQuestion.vue";
export default {
  //components: { TooltipQuestion },
  name: "ResumoAluno",
  computed: {
    aluno() {
      return this.$store.state.aluno.aluno;
    },
    fontSize() {
      return this.$store.state.util.font;
    },
    link() {
      return this.$store.state.util.link;
    }
  },
  methods: {
    primeiroNome: primeiroNome
  }
};
</script>
